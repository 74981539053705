<template>
  <VueDatePicker v-model="date" range :preset-ranges="presetRanges">
    <template #yearly="{ label, range, presetDateRange }">
      <span @click="presetDateRange(range)">{{ label }}</span>
    </template>
  </VueDatePicker>

  <div class="container">
    <div class="uptime-container">
      <div class="panel">
        <div class="panel-label">Connected Clients</div>
        <div class="panel-value">{{ connectedClients }}</div>
      </div>
      <div class="panel">
        <div class="panel-label">Redis Uptime</div>
        <div class="panel-value">{{ redisUptime }}</div>
      </div>
      <div class="panel">
        <div class="panel-label">Total Keys</div>
        <div class="panel-value">{{ totalKeys }}</div>
      </div>
    </div>
    <div class="chart-container">
      <canvas id="memoryChart"></canvas>
    </div>
    <div class="chart-container">
      <canvas id="opsPerSecondChart"></canvas>
    </div>
    <div class="chart-container">
      <canvas id="netByteChart"></canvas>
    </div>
    <div class="chart-container">
      <canvas id="hitMissChart"></canvas>
    </div>

    <div class="chart-container">
      <canvas id="commandChart"></canvas>
    </div>
  </div>
</template>

<script>
import { PrometheusDriver,PrometheusQueryDate } from 'prometheus-query';
import moment from 'moment';
import Chart from 'chart.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import { ref } from 'vue';
import store from "@/store";


const presetRanges = ref([
  { label: 'Today', range: [new Date(), new Date()] },
  { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
  {
    label: 'Last month',
    range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
  },
  { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] },
  {
    label: 'This year (slot)',
    range: [startOfYear(new Date()), endOfYear(new Date())],
    slot: 'yearly',
  },
]);

export default {
  name: "Usage",
  components: { VueDatePicker },

  data() {
    return {
      connectedClients: 0,
      redisUptime: '',
      totalKeys: 0,
      opsPerSecond: 0 ,
      date : [startOfMonth(new Date()), endOfMonth(new Date())],
      presetRanges
    };
  },
  watch: {
    // whenever question changes, this function will run
    date(newDate, oldDate) {
      if (newDate !== null) {
        this.fetchData();
      }
    }
  },
  async created() {
    const ServerURL  = process.env.METRICS_HOST
    console.log("fffffffffff")
    console.log(ServerURL)
  },
  mounted() {

    this.fetchData();
  },
  methods: {
    renderChartCommand(data) {
      const excludedCmds = ['client|setname', 'client|config','info','slowlog|len','slowlog|get','latency|latest','config|get','latency|histogram','command|docs','ping','auth','hello'];
      const filteredData = data.filter(item => !excludedCmds.includes(item.metric.labels.cmd));
      const commands = filteredData.map(item => item.metric.labels.cmd);
      const counts = filteredData.map(item => item.value.value).filter(cmd => !excludedCmds.includes(cmd));
      console.log(commands,counts)
      const ctx = document.getElementById('commandChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: commands,
          datasets: [
            {
              label: 'Command Count',
              data: counts,
            },
          ],
        },
        options: {

          maintainAspectRatio: false,
          // Customize the chart options as per your requirements
        },
      });
    },
    renderChartMemory(data) {
      const timestamps = data[0]?.values.map(value => new Date(value.time).toDateString());
      const values = data[0]?.values.map(value => value.value / 1024 / 1024);

      const ctx = document.getElementById('memoryChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: 'Memory Usage',
              data: values,
              pointRadius:0,
              backgroundColor: 'rgba(75, 192, 192, 0.2)', // Update the background color
              borderColor: 'rgba(75, 192, 192, 1)', // Update the border color
              borderWidth: 1,

              fill: 'start',
            },
          ],
        },
        options: {
          fill:false,
          radius: 1,

          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Point Style: pointStyle: false',
              color: 'rgb(255, 99, 132)', // Update the title color
            },
          },
          interaction: {
            intersect: true,
          },
          scales: {
            y: {
              id: "MB",
              position: "left",
              display: true,
              title: {
                display: true,
                text: 'MB',
                color: 'rgb(54, 162, 235)', // Update the y-axis title color
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)', // Update the grid color
              },
            },
            x: {
              display: true,
              scaleLabel: {
                display: true,
                text: 'Month',
                color: 'rgb(75, 192, 192)', // Update the x-axis title color
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)', // Update the grid color
              },
            },
          },
        },
      });
    },
    renderChartOpsPerSecond(data){
      const timestamps = data[0]?.values.map(value => new Date(value.time).toDateString());
      const values = data[0]?.values.map(value => value.value);

      console.log(timestamps);
      console.log(values);

      const ctx = document.getElementById('opsPerSecondChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              pointRadius:0,
              label: 'Ops/Second',
              data: values,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              fill: 'start',
            },

          ],
        },
        options: {
          maintainAspectRatio: false,
          // Customize the chart options as per your requirements
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'MMM D',
                  week: 'MMM D',
                  month: 'MMM YYYY',
                  quarter: '[Q]Q - YYYY',
                  year: 'YYYY',
                },
              },
            },
          },
        },
      });
    },
    renderChartNetwork(inputData,outputData){
      const timestamps = inputData[0]?.values.map(value => new Date(value.time).toDateString());
      const input = inputData[0]?.values.map(value => value.value);
      const output = outputData[0]?.values.map(value => value.value);



      const ctx = document.getElementById('netByteChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              pointRadius:0,
              label: 'Input',
              data: input,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              fill: 'start',
            },
            {
              pointRadius:0,
              label: 'Output',
              data: output,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgb(148,29,157)',
              borderWidth: 1,
              fill: 'start',
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          // Customize the chart options as per your requirements
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'MMM D',
                  week: 'MMM D',
                  month: 'MMM YYYY',
                  quarter: '[Q]Q - YYYY',
                  year: 'YYYY',
                },
              },
            },
          },
        },
      });
    },
    renderHitMissKeyspace(hitData,missData){
      const timestamps = hitData[0]?.values.map(value => new Date(value.time).toDateString());
      const hit = hitData[0]?.values.map(value => value.value);
      const miss = missData[0]?.values.map(value => value.value);

      console.log("kj")
      console.log(hit)
      console.log(miss)

      const ctx = document.getElementById('hitMissChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              pointRadius:0,
              label: 'hit',
              data: hit,
              backgroundColor: 'rgba(243,226,226,0.2)',
              borderColor: 'rgb(212,176,215)',
              borderWidth: 1,
              fill: 'start',
            },
            {
              pointRadius:0,
              label: 'miss',
              data: miss,
              backgroundColor: 'rgb(178,218,170)',
              borderColor: 'rgb(128,109,133)',
              borderWidth: 1,
              fill: 'start',
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          // Customize the chart options as per your requirements
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'MMM D',
                  week: 'MMM D',
                  month: 'MMM YYYY',
                  quarter: '[Q]Q - YYYY',
                  year: 'YYYY',
                },
              },
            },
          },
        },
      });
    },
    handleTimeChange() {
      // Handle the selected time change here
      // You can access the selected time via this.selectedTime
    },


    async fetchData() {
      const prometheus = new PrometheusDriver({
        endpoint: "https://metrics.landofbytes.com",
        baseURL: "/api/v1", // default value,
        headers: {
          "TOKEN": store.getters.token
        },
      });

      // Example query to fetch Redis Exporter metrics for commands
      const commandQuery = 'redis_commands_total';

      // Example query to fetch Redis Exporter metrics for uptime
      const uptimeQuery = 'redis_uptime_in_seconds';

      // Example query to fetch Redis Exporter metrics for connected clients
      const clientsQuery = 'redis_connected_clients';

      // Example query to fetch Redis Exporter metrics for total keys
      const keysQuery = 'redis_db_keys{db="db0"}';

      // Example query to fetch Redis Exporter metrics for memory usage
      const memoryQuery = 'redis_memory_used_bytes';

      // Example query to fetch Redis ops per second
      const opsPerSecondQuery = 'rate(redis_commands_processed_total[1m])'

      const netInputBytesQuery = 'rate(redis_net_input_bytes_total[5m])'
      const netOutputBytesQuery = 'rate(redis_net_output_bytes_total[5m])'

      const hitKeyspaceQuery = 'rate(redis_keyspace_hits_total[1m])'
      const missKeyspaceQuery = 'rate(redis_keyspace_misses_total[1m])'

      try {
        // Fetch data for commands
        const commandResponse = await prometheus.instantQuery(commandQuery);
        const commandData = commandResponse.result;
        this.renderChartCommand(commandData);

        // Fetch data for uptime
        const uptimeResponse = await prometheus.instantQuery(uptimeQuery);
        const uptimeData = uptimeResponse.result;
        this.updateUptimeText(uptimeData);

        // Fetch data for connected clients
        const clientsResponse = await prometheus.instantQuery(clientsQuery);
        const clientsData = clientsResponse.result;
        this.updateConnectedClients(clientsData);

        // Fetch data for total keys
        const keysResponse = await prometheus.instantQuery(keysQuery);
        const keysData = keysResponse.result;
        this.updateTotalKeys(keysData);

        // Fetch data for memory usage
        const step = 6 * 60 * 20 ;
        console.log("date")
        console.log(this.date)
        const memoryResponse = await prometheus.rangeQuery(memoryQuery, this.date[0],this.date[1],step);
        const memoryData = memoryResponse.result;
        console.log(memoryData)
        this.renderChartMemory(memoryData);

        // Fetch data for ops per second
        const opsPerSecondRespone = await prometheus.rangeQuery(opsPerSecondQuery,this.date[0],this.date[1],step)
        const opsPerSecondData = opsPerSecondRespone.result
        this.renderChartOpsPerSecond(opsPerSecondData )


        const netInputByteRespone = await prometheus.rangeQuery(netInputBytesQuery,this.date[0],this.date[1],step)
        const netInputByteData = netInputByteRespone.result
        const netOutputByteRespone = await prometheus.rangeQuery(netOutputBytesQuery,this.date[0],this.date[1],step)
        const netOutPutByteData = netOutputByteRespone.result
        this.renderChartNetwork(netInputByteData,netOutPutByteData)


        const hitKeyspaceRespone = await prometheus.rangeQuery(hitKeyspaceQuery,this.date[0],this.date[1],step)
        const hitKeyspaceData = hitKeyspaceRespone.result
        const missKeyspaceRespone = await prometheus.rangeQuery(missKeyspaceQuery,this.date[0],this.date[1],step)
        const missKeyspaceData = missKeyspaceRespone.result
        this.renderHitMissKeyspace(hitKeyspaceData,missKeyspaceData)





      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    },
    updateUptimeText(data) {
      const uptimeInSeconds = data[0]?.value?.value;
      const uptimeText = this.formatUptime(uptimeInSeconds);
      this.redisUptime = uptimeText;
    },
    formatUptime(uptimeInSeconds) {
      const days = Math.floor(uptimeInSeconds / 86400);
      const hours = Math.floor((uptimeInSeconds % 86400) / 3600);
      const minutes = Math.floor((uptimeInSeconds % 3600) / 60);
      const seconds = uptimeInSeconds % 60;

      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    },
    updateConnectedClients(data) {
      const clients = data[0]?.value?.value;
      this.connectedClients = clients;
    },
    updateTotalKeys(data) {
      const totalKeys = data[0]?.value?.value;
      this.totalKeys = totalKeys;
    },
    updateOpsPerSecond(data){
      const opsPerSec = data[0]?.value?.value;
      this.opsPerSecond = opsPerSec
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
}

.chart-container {
  flex: 1 1 100%;
  width: 80%;
  justify-content: center;
  height: 300px;
  max-width: 100%;
  margin-bottom: 40px;

}

.uptime-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  width: 200px;
  margin-bottom: 10px;
}

.panel-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.panel-value {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.vue-datepicker {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
}


@media (min-width: 768px) {
  /*.chart-container {*/
  /*  width: 400px;*/
  /*  height: 400px;*/
  /*}*/

  /*.panel {*/
  /*  width: 400px;*/
  /*  height: 400px;*/
  /*  !*max-width: none;*!*/
  /*}*/
  .chart-container {
    flex: 1 1 100%;
    position: relative;
    height:40vh;
    width:80vw
  }

  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    width: 200px;
    margin-bottom: 10px;
  }

}
</style>

